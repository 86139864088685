<template>
  <div>
    <b-overlay spinner-variant="primary" :show="isLoading">
      <div class="row mb-2">
        <div class="col-12 col-md-8 d-flex">
          <b-form-input v-model="query" placeholder="Пошук за email">
          </b-form-input>
          <b-button
            :disabled="query.length < 5 || query.indexOf('@') < 0"
            @click="searchByEmail"
            variant="primary"
            class="ml-1"
          >
            Шукати
          </b-button>
        </div>
        <div class="col-12 col-md-4">
          <b-button @click="openAddingModal" variant="success" class="w-100">
            + Додати користувача
          </b-button>
        </div>
      </div>
      <template v-if="isSearch">
        <div v-if="searchResults.length">
          <user-list-item v-for="u in searchResults" :key="u.uid" :u="u" />
        </div>
        <div v-else>
          <b-alert show variant="primary" class="py-2 px-1">
            Нічого не знайдено
          </b-alert>
        </div>
        <div class="d-flex justify-content-center">
          <b-button variant="primary" @click="isSearch = false">
            Усі користувачі
          </b-button>
        </div>
      </template>
      <template v-if="!isSearch">
        <user-list-item v-for="u in users" :key="u.uid" :u="u" />
      </template>
      <div class="col-12 d-flex my-2 justify-content-center">
        <b-button v-if="last_doc" variant="primary" @click="getUsers">
          Завантажити ще {{ per_page }}
        </b-button>
      </div>
    </b-overlay>
    <b-modal centered hide-footer hide-header v-model="showAddModal">
      <b-overlay spinner-variant="primary" :show="newUserLoading">
        <h2 class="mt-1 mb-1">Додавання нового користувача</h2>
        <div class="form-group">
          <label>Прізвище</label>
          <b-form-input v-model="new_user.last_name"> </b-form-input>
        </div>
        <div class="form-group">
          <label>Імʼя</label>
          <b-form-input v-model="new_user.first_name"> </b-form-input>
        </div>
        <div class="form-group">
          <label>По-батькові</label>
          <b-form-input v-model="new_user.middle_name"> </b-form-input>
        </div>
        <div class="form-group">
          <label>Email</label>
          <b-form-input v-model="new_user.email"> </b-form-input>
        </div>
        <div class="form-group">
          <label>Пароль</label>
          <b-form-input v-model="new_user.password"> </b-form-input>
        </div>
        <div class="form-group">
          <b-button
            :disabled="creating_disabled"
            @click="createNewUser"
            class="w-100 d-block"
            variant="primary"
          >
            Створити користувача
          </b-button>
          <b-button
            @click="showAddModal = false"
            class="w-100 d-block mt-1"
            variant="outline-secondary"
          >
            Скасувати
          </b-button>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import userListItem from "@/components/user-list-item.vue";
export default {
  components: {
    userListItem,
  },
  data() {
    return {
      newUserLoading: false,
      isLoading: false,
      showAddModal: false,
      query: "",
      users: [],
      searchQuery: "",
      searchResults: [],
      isSearch: false,
      page: 1,
      per_page: 24,
      last_doc: null,
      new_user: {
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        middle_name: "",
      },
    };
  },
  computed: {
    creating_disabled() {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      let disabled = false;
      for (var key in this.new_user) {
        if (key != "email") {
          if (this.new_user[key].length < 3) {
            disabled = true;
          }
        } else {
          if (!re.test(String(this.new_user.email).toLowerCase())) {
            disabled = true;
          }
        }
      }

      return disabled;
    },
  },
  methods: {
    createNewUser() {
      this.newUserLoading = true;
      let data = JSON.stringify(this.new_user);
      this.$api
        .get("/user/create/?data=" + data)
        .then((response) => {
          this.newUserLoading = false;
          this.$toasts.success("Користувача створено");
          window.location.reload();
          console.log(response);
        })
        .catch((e) => {
          this.newUserLoading = true;
          this.$toasts.success("Сталася помилка");
          console.log(e);
        });
    },
    openAddingModal() {
      this.showAddModal = true;
    },
    async searchByEmail() {
      this.isLoading = true;
      var args = {
        collectionName: "users",
        where: ["email", "==", this.query],
        limitBy: 10,
      };
      var result = await this.$db.getCollection(args);

      this.searchResults = [];
      if (result.docs.length) {
        result.docs.forEach((doc) => {
          this.searchResults.push(doc);
        });
      }

      this.isSearch = true;
      this.isLoading = false;
    },
    async getUsers() {
      this.isLoading = true;
      var args = {
        collectionName: "users",
        limitBy: this.per_page,
        order: ["first_visit", "asc"],
      };
      if (this.page !== 1 && this.last_doc) {
        args["startAfter"] = this.last_doc;
      }
      var result = await this.$db.getCollection(args);
      result.docs.forEach((doc) => {
        this.users.push(doc);
      });
      this.last_doc = null;
      if (result.docs.length == this.per_page) {
        this.last_doc = result.lastVisible;
      }
      this.page++;
      this.isLoading = false;
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<style lang="scss" scoped></style>
